<div class="table-wrap">
  <div #table class="inner-container scroll-style">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Position Column -->
      <ng-container matColumnDef="earningDate" sticky>
        <th mat-header-cell *matHeaderCellDef>Earning Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.earningDate | date: 'MMM d, y' }}
        </td>
      </ng-container>

      <!-- Fiscal Quarter Column -->
      <ng-container matColumnDef="fiscalQuarter">
        <th mat-header-cell *matHeaderCellDef>Fiscal Quarter</th>
        <td mat-cell *matCellDef="let element">
          {{ element.fiscalQuarter }}
        </td>
      </ng-container>

      <!-- Estimated Column -->
      <ng-container matColumnDef="estimatedEPS">
        <th mat-header-cell *matHeaderCellDef class="left-border">Estimated EPS</th>
        <td mat-cell *matCellDef="let element" class="left-border">
          <span *ngIf="element.estimatedEPS !== null">
            {{ element.estimatedEPS | number: '1.2-2' }}
          </span>
          <span *ngIf="element.estimatedEPS === null">-</span>
        </td>
      </ng-container>

      <!-- Actual Column -->
      <ng-container matColumnDef="actualEPS">
        <th mat-header-cell *matHeaderCellDef>Actual EPS</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.actualEPS !== null">
            {{ element.actualEPS | number: '1.2-2' }}
          </span>
          <span *ngIf="element.actualEPS === null">-</span>
        </td>
      </ng-container>

      <!-- EPS Surprise Column -->
      <ng-container matColumnDef="epsSurprise">
        <th mat-header-cell *matHeaderCellDef>EPS Surprise</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{ 'danger': element.epsSurprise < 0, 'success': element.epsSurprise > 0 }"
        >
          <div class="bar-wrapper">
            <span *ngIf="element.epsSurprise !== null && element.epsSurprise !== 0">
              {{ element.epsSurprise | number: '1.2-2' }}%
            </span>
            <span *ngIf="element.epsSurprise === null">-</span>
            <span *ngIf="element.epsSurprise === 0">0%</span>
            <div class="bar" [style.width.%]="element.epsSurpriseBarWidth"></div>
          </div>
        </td>
      </ng-container>

      <!-- Closing Price Before Earnings Column -->
      <ng-container matColumnDef="closingPriceBeforeEarnings">
        <th mat-header-cell *matHeaderCellDef class="large left-border">
          Closing Price Before Earnings
        </th>
        <td mat-cell *matCellDef="let element" class="left-border">
          <span *ngIf="element.closingPriceBeforeEarnings !== null">
            {{ element.closingPriceBeforeEarnings | number: '1.2-2' }}
          </span>
          <span *ngIf="element.closingPriceBeforeEarnings === null">-</span>
        </td>
      </ng-container>

      <!-- Opening Price After Earnings Column -->
      <ng-container matColumnDef="openingPriceAfterEarnings">
        <th mat-header-cell *matHeaderCellDef class="large">
          Opening Price After Earnings
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.openingPriceAfterEarnings !== null">
            {{ element.openingPriceAfterEarnings | number: '1.2-2' }}
          </span>
          <span *ngIf="element.openingPriceAfterEarnings === null">-</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="openingPriceChangePercent">
        <th mat-header-cell *matHeaderCellDef class="large">
          Opening Price Change, %
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{ 'danger': element.openingPriceChangePercent < 0, 'success': element.openingPriceChangePercent > 0 }"
        >

          <div class="bar-wrapper">
            <span *ngIf="element.openingPriceChangePercent !== null && element.openingPriceChangePercent !== 0">
              {{ element.openingPriceChangePercent | number: '1.2-2' }}%
            </span>
            <span *ngIf="element.openingPriceChangePercent === null">-</span>
            <span *ngIf="element.openingPriceChangePercent === 0">0%</span>

            <div class="bar" [style.width.%]="element.openingPriceChangeBarWidth"></div>
          </div>
        </td>
      </ng-container>

      <!-- Closing Price After Earnings Column -->
      <ng-container matColumnDef="closingPriceAfterEarnings">
        <th mat-header-cell *matHeaderCellDef class="large">
          Closing Price After Earnings
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.closingPriceAfterEarnings !== null">
            {{ element.closingPriceAfterEarnings | number: '1.2-2' }}
          </span>
          <span *ngIf="element.closingPriceAfterEarnings === null">-</span>
        </td>
      </ng-container>

      <!-- Closing Price Change, % Column -->
      <ng-container matColumnDef="closingPriceChangePercent">
        <th mat-header-cell *matHeaderCellDef class="large">
          Closing Price Change, %
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{ 'danger': element.closingPriceChangePercent < 0, 'success': element.closingPriceChangePercent > 0 }"
        >

          <div class="bar-wrapper">
            <span *ngIf="element.closingPriceChangePercent !== null && element.closingPriceChangePercent !== 0">
              {{ element.closingPriceChangePercent | number: '1.2-2' }}%
            </span>
            <span *ngIf="element.closingPriceChangePercent === null">-</span>
            <span *ngIf="element.closingPriceChangePercent === 0">0%</span>

            <div class="bar" [style.width.%]="element.closingPriceBarWidth"></div>
          </div>
        </td>
      </ng-container>

      <!-- On 7th Day Closing Price Column -->
      <ng-container matColumnDef="on7thDayClosingPrice">
        <th mat-header-cell *matHeaderCellDef class="large left-border">
          On 7th Day Closing Price
        </th>
        <td mat-cell *matCellDef="let element" class="left-border">
          <span *ngIf="element.on7thDayClosingPrice !== null">
            {{ element.on7thDayClosingPrice | number: '1.2-2' }}
          </span>
          <span *ngIf="element.on7thDayClosingPrice === null">-</span>
        </td>
      </ng-container>

      <!-- On 7th Day Closing Price Change, % Column -->
      <ng-container matColumnDef="on7thDayClosingPriceChangePercent">
        <th mat-header-cell *matHeaderCellDef class="large">
          On 7th Day Closing Price Change, %
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{ 'danger': element.on7thDayClosingPriceChangePercent < 0, 'success': element.on7thDayClosingPriceChangePercent > 0 }"
        >
          <div class="bar-wrapper">
            <span *ngIf="element.on7thDayClosingPriceChangePercent !== null && element.on7thDayClosingPriceChangePercent !== 0">
              {{ element.on7thDayClosingPriceChangePercent | number: '1.2-2' }}%
            </span>
            <span *ngIf="element.on7thDayClosingPriceChangePercent === null">-</span>
            <span *ngIf="element.on7thDayClosingPriceChangePercent === 0">0%</span>

            <div class="bar" [style.width.%]="element.on7thDayClosingPriceBarWidth"></div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns; let i = index"
        [ngClass]="i % 2 == 0 ? null : 'row-marked'"
      ></tr>
    </table>
  </div>
</div>
