import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';
import { round } from '@u/utils';
import { IEarningsDetails } from '@t/earning-calendar/earnings-calendar.types';
import { IEarningsTableItem } from './eps-table.model';

@Component({
  selector: 'app-eps-table',
  templateUrl: './eps-table.component.html',
  styleUrls: ['./eps-table.component.scss'],
})
export class EpsTableComponent implements OnInit, AfterViewChecked, OnChanges {
  public dataSource: IEarningsTableItem[] = [];
  public displayedColumns: string[] = [
    'earningDate',
    'fiscalQuarter',
    'estimatedEPS',
    'actualEPS',
    'epsSurprise',
    'closingPriceBeforeEarnings',
    'openingPriceAfterEarnings',
    'openingPriceChangePercent',
    'closingPriceAfterEarnings',
    'closingPriceChangePercent',
    'on7thDayClosingPrice',
    'on7thDayClosingPriceChangePercent',
  ];

  @Input() formattedEarnings: IEarningsDetails[] = [];

  @ViewChild('table') table: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateTableStyles();
  }

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewChecked() {
    this.updateTableStyles();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formattedEarnings) {
      this.dataSource = this.transformEarningsToTableData(changes.formattedEarnings.currentValue);
    }
  }

  private updateTableStyles(): void {
    const table = this.table.nativeElement;
    table.clientWidth === table.scrollWidth
      ? table.classList.remove('table-sticky-style')
      : table.classList.add('table-sticky-style');
  }

  public transformEarningsToTableData(earnings: IEarningsDetails[]): IEarningsTableItem[] {
    const maxAbsNextDayOpenPercentChange = this.getMaxAbsoluteValue(earnings, 'nextDayOpenPercentChange');
    const maxAbsEpsSurprise = this.getMaxAbsoluteValue(earnings, 'surpriseEps');
    const maxAbsNextDayClosingPrice = this.getMaxAbsoluteValue(earnings, 'nextDayClosePercentChange');
    const maxAbsOn7thDayClosingPrice = this.getMaxAbsoluteValue(earnings, 'sevenDayClosePercentChange');

    return _.orderBy(earnings, ['periodYear', 'period'], ['desc', 'desc'])
      .map((details) => {
        return {
          earningDate: details.reportDate,
          fiscalQuarter: `${details.period}, ${details.periodYear}`,
          estimatedEPS: details.estimate,
          actualEPS: details.actual,
          epsSurprise: details.surpriseEps,
          closingPriceBeforeEarnings: details.closeBeforeDay,
          openingPriceAfterEarnings: details.openNextDay,
          openingPriceChangePercent: details.nextDayOpenPercentChange,
          openingPriceChangeBarWidth: round(Math.abs(details.nextDayOpenPercentChange) / maxAbsNextDayOpenPercentChange * 100, 2),
          closingPriceAfterEarnings: details.closeNextDay,
          closingPriceChangePercent: details.nextDayClosePercentChange,
          closingPriceBarWidth: round(Math.abs(details.nextDayClosePercentChange) / maxAbsNextDayClosingPrice * 100, 2),
          on7thDayClosingPrice: details.closeSevenDay,
          on7thDayClosingPriceChangePercent: details.sevenDayClosePercentChange,
          epsSurpriseBarWidth: round(Math.abs(details.surpriseEps) / maxAbsEpsSurprise * 100, 2),
          on7thDayClosingPriceBarWidth: round(Math.abs(details.sevenDayClosePercentChange) / maxAbsOn7thDayClosingPrice * 100, 2),
        };
      });
  }

  private getMaxAbsoluteValue<T, F extends keyof T>(objectsArray: T[], fieldName: F): number {
    return Math.max(
      ...objectsArray
        .filter((item) => item[fieldName] !== null && !Number.isNaN(Number(item[fieldName])))
        .map((item) => Math.abs(Number(item[fieldName])))
    );
  }
}
