import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IEarningsDetails } from '@t/earning-calendar/earnings-calendar.types';
import * as _ from 'lodash';
import { ApexAxisChartSeries, ApexOptions, ApexXAxis, ApexYAxis } from 'ng-apexcharts/lib/model/apex-types';
import { fromEvent, Subscriber } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { IChartDataSourceItem } from './eps-chart.model';

@Component({
  selector: 'app-eps-chart',
  templateUrl: './eps-chart.component.html',
  styleUrls: ['./eps-chart.component.scss'],
})
export class EpsChartComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() isActive = false;
  @Input() formattedEarnings: IEarningsDetails[] = [];

  @ViewChild('chartContainer') component: ElementRef;

  public chartHeight = 280;
  public minBarWidth = 32;
  public pageContainerPaddings = 32;
  public chartHorizontalPaddings = 150;

  public initialEstimateEpsSeriesSettings = {
    name: 'Estimated EPS',
    data: [],
    color: 'var(--estimate-eps-bar-style-color)',
  };

  public initialActualEpsSeriesSettings = {
    name: 'Actual EPS',
    data: [],
    color: 'var(--actual-eps-bar-style-color)',
  };

  public chartWidth: number = window.innerWidth - this.pageContainerPaddings;
  public dataSource: IChartDataSourceItem[] = [];
  public isShowZeroLine = false;

  public series: ApexAxisChartSeries = [
    { ...this.initialEstimateEpsSeriesSettings, data: [] },
    { ...this.initialActualEpsSeriesSettings, data: [] },
  ];

  public xAxis: ApexXAxis = {
    categories: [],
    labels: {
      style: {
        fontSize: '10px',
        colors: 'var(--main-font-color)',
      },
    },
  };

  public yAxis: ApexYAxis = {
    decimalsInFloat: 2,
    labels: {
      style: {
        fontSize: '10px',
        colors: 'var(--main-font-color)',
      },
    },
  };

  public chartOptions: ApexOptions = {
    chart: {
      id: 'eps-chart',
      height: this.chartHeight,
      type: 'bar',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 200,
        animateGradually: {
          enabled: true,
          delay: 50,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 200,
        },
      },
      zoom: {
        enabled: false,
        type: 'x',
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          offsetY: 0.5,
          strokeDashArray: 0,
          borderWidth: 1,
          fillColor: '#AFAFC0',
          borderColor: '#AFAFC0',
        },
      ],
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '14px',
      itemMargin: {
        vertical: 10,
        horizontal: 16,
      },
      labels: {
        colors: 'var(--main-font-color)',
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
      lineCap: 'round',
    },
    plotOptions: {
      bar: {
        columnWidth: '80%',
        borderRadius: 2,
        dataLabels: {
          maxItems: 48,
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
      offsetY: -20,
    },
    grid: {
      show: true,
      borderColor: 'var(--table-border-color)',
      strokeDashArray: 0,
      position: 'back',
    },
  };

  private subscriber = new Subscriber();

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.subscriber.add(
      fromEvent(window, 'resize')
        .pipe(
          debounceTime(500),
          map(() => window.innerWidth),
          distinctUntilChanged(),
        )
        .subscribe((width) => {
          const seriesNumber = this.series[0].data.length;
          this.updateChartSize(width, seriesNumber);
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formattedEarnings) {
      this.updateChart(changes.formattedEarnings.currentValue);
    }

    const seriesNumber = this.series[0].data.length;
    const width = window.innerWidth;

    this.updateChartSize(width, seriesNumber);
  }

  private updateChart(earnings: IEarningsDetails[]): void {
    this.dataSource = this.transformEarningsToTableData(earnings);

    const xAxis = this.dataSource.map((details) => details.xAxisLabel);

    this.xAxis = {
      ...this.xAxis,
      range: xAxis.length - 1,
      categories: xAxis,
    };

    this.isShowZeroLine = this.dataSource
      .flatMap((item) => [item.actualEps, item.estimatedEps])
      .some((item) => item < 0);

    // or calculate from min absolute value (not 0 or null) divided by 10 to make it visible in all cases
    const zeroBarValue = 0.0005;

    this.series = [
      {
        ...this.initialEstimateEpsSeriesSettings,
        data: this.dataSource.map((details) => {
          if (details.estimatedEps === null) {
            return null;
          }

          return details.estimatedEps || zeroBarValue;
        }),
      },
      {
        ...this.initialActualEpsSeriesSettings,
        data: this.dataSource.map((details) => {
          if (details.actualEps === null) {
            return null;
          }

          return details.actualEps || zeroBarValue;
        }),
      },
    ];
  }

  private updateChartSize(width: number, seriesNumber: number): void {
    const minChartWidth = this.minBarWidth * seriesNumber + this.chartHorizontalPaddings;

    this.chartWidth = Math.max(width - this.pageContainerPaddings, minChartWidth);
    this.chartOptions.chart = { ...this.chartOptions.chart, width: this.chartWidth };
  }

  private transformEarningsToTableData(earnings: IEarningsDetails[]): IChartDataSourceItem[] {
    return _.orderBy(earnings, ['periodYear', 'period'], ['asc', 'asc']).map((details) => ({
      reportDate: details.reportDate,
      year: details.periodYear,
      period: details.period,
      xAxisLabel: this.getXAxisLabel(details.periodYear, details.period),
      estimatedEps: details.estimate,
      actualEps: details.actual,
    }));
  }

  // Format:  Q<x>’<YY>, example: Q1’22.
  // Where: <x>. Quarter number: 1,2,3 or 4. <YY>. Last 2 digits of the year.
  private getXAxisLabel(year: number, period: string): string {
    return `${period}\`${String(year).slice(-2)}`;
  }
}
