<div class="container scroll-style">
  <table mat-table [dataSource]="data" class="mat-elevation-z8 table-styles table-main-style scroll-style">
    <ng-container matColumnDef="price" *ngIf="!surprise">
      <th mat-header-cell *matHeaderCellDef>Price</th> danger
      <td mat-cell *matCellDef="let person">
        <span class="data">
          <span class="success">Up</span>
          <span class="line"> | </span>
          <span class="danger">Down</span>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="price" *ngIf="surprise">
      <th mat-header-cell *matHeaderCellDef>Surprise</th>
      <td mat-cell *matCellDef="let person">
        <span class="data">
          <span class="success">Beat</span>
          <span class="line"> | </span>
          <span class="danger">Miss</span>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="min">
      <th mat-header-cell *matHeaderCellDef>Min</th>
      <td mat-cell *matCellDef="let element">
        <span class="data">
          <span *ngIf="element.upListMin; else emptyBlock" class="success">
            {{ element.upListMin | number: '1.0-2' }}%
          </span>
          <span class="line"> | </span>
          <span *ngIf="element.minDown; else emptyBlock" class="danger">
            {{ element.minDown | number: '1.0-2' }}%
          </span>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="avg">
      <th mat-header-cell *matHeaderCellDef>Avg</th>
      <td mat-cell *matCellDef="let element">
        <span class="data">
          <span *ngIf="element.upListAvg !== 0; else emptyBlock" class="success">
            {{ element.upListAvg | number: '1.0-2' }}%
          </span>
          <span class="line"> | </span>
          <span *ngIf="element.downListAvg !== 0; else emptyBlock" class="danger">
            {{ element.downListAvg | number: '1.0-2' }}%
          </span>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="max">
      <th mat-header-cell *matHeaderCellDef>Max</th>
      <td mat-cell *matCellDef="let element">
        <span class="data" >
          <span *ngIf="element.upListMax; else emptyBlock" class="success">
            {{ element.upListMax | number: '1.0-2' }}%
          </span>
          <span class="line"> | </span>
          <span *ngIf="element.maxDown; else emptyBlock" class="danger">
            {{ element.maxDown | number: '1.0-2' }}%
          </span>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<ng-template #emptyBlock><span>-</span></ng-template>
