import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { Component, OnDestroy, OnInit, input } from '@angular/core';
import {
  EasternTimeZoneName,
  ExchangeCountriesCodes,
  Features,
  UserSettings,
  earningsCalendarFilterDebounceTime,
  saveTabStateDebounceTime,
} from '@const';
import { RelativelyToMarketTimeOptions } from '@mod/admin';
import { EarningsCalendarService } from '@s/calendars/earnings-calendar.service';
import { EditionsService } from '@s/editions.service';
import { HistoricalDataService } from '@s/historical-data.service';
import { ObservableService } from '@s/observable.service';
import { SearchPopupService } from '@s/search-popup.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { UserDataService } from '@s/user-data.service';
import {
  IEarningsDetails,
  IEarningsDetailsApiResponse,
  IHistoricalEpsPerformance,
  INextDayClosingPriceChanges,
  INextEarnings,
  IPrevEarnings,
  IPriceMoveReactionProbability,
} from '@t/earning-calendar/earnings-calendar.types';
import { IHistoricalDataItem } from '@t/services/historical-data.types';
import { average, round } from '@u/utils';
import moment from 'moment';
import { BehaviorSubject, Subject, Subscription, combineLatest, from, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-earnings-analysis-content',
  templateUrl: './earnings-analysis-content.component.html',
  styleUrls: ['./earnings-analysis-content.component.scss'],
})
export class EarningsAnalysisContentComponent implements OnInit, OnDestroy {
  protected isChartTab = input(false);
  protected isLoading = true; // show spinner on start
  protected isContentBlurred = true; // blur content while request is pending, except first one
  protected isDataReceived = false;

  protected readonly features = Features;

  protected selectedTab = 1;
  protected readonly tabState$ = new Subject<number>();

  protected formattedEarnings: IEarningsDetails[] = [];
  protected earningsBeforeCurrentDate: IEarningsDetails[] = [];

  protected dataGridSource: IEarningsDetails[] = []; // filtered items for data-grid
  protected chartSource: IEarningsDetails[] = []; // filtered items for chart

  protected prevEarnings: IPrevEarnings = null;
  protected nextEarnings: INextEarnings = null;
  protected priceMoveReactionProbability: IPriceMoveReactionProbability = null;
  protected nextDayClosingPriceChanges: INextDayClosingPriceChanges = null;
  protected historicalEpsPerformance: IHistoricalEpsPerformance = null;

  protected readonly currentSymbol$ = new BehaviorSubject<ISymbol>(null);
  protected readonly expectedMoveHasData$ = new BehaviorSubject<boolean>(false);

  protected readonly earningsBeforeCurrentDate$ = new BehaviorSubject<IEarningsDetails[]>([]); // also, to use for max-items filter
  protected readonly maxItemsToUse$ = new BehaviorSubject<number | null>(null);
  private readonly defaultMaxDataToUse = 21;

  protected readonly maxItemsNumberForPriceMoveReactionProbability = 48;
  protected readonly maxItemsNumberForNextDayClosingPriceChanges = 48;
  protected readonly maxItemsNumberForHistoricalEpsPerformance = 48;

  protected readonly relativelyToMarketTimeLabels: Record<RelativelyToMarketTimeOptions, string> = {
    [RelativelyToMarketTimeOptions.BeforeMarket]: 'Before market open',
    [RelativelyToMarketTimeOptions.DuringMarket]: 'During market hours',
    [RelativelyToMarketTimeOptions.AfterMarket]: 'After market close',
  };

  protected rangeValue = this.defaultMaxDataToUse;
  protected readonly options: Options = {
    floor: 1,
    ceil: this.defaultMaxDataToUse,
    step: 1,
    translate: (value: number): string => {
      return value === this.defaultMaxDataToUse ? 'Max' : `${value}`;
    },
    customValueToPosition: (val: number): number => {
      return --val === 20 ? 1 : (1 / 20.8) * val;
    },
  };

  protected readonly overnightHint = `
    Overnight Move\n
    If ABC reports earnings After the market Close, then Overnight Move is a difference between Next trading day Open price and Close price of This day.\n
    If ABC reports earnings Before the market Open, then Overnight Move is a difference between This day Open price and Close price of the Previous trading day.
  `;

  private readonly subscriber = new Subscription();
  private readonly filtersChanged$ = new Subject<number>();

  constructor(
    private symbolsService: SymbolsService,
    private historicalDataService: HistoricalDataService,
    private earningsCalendarService: EarningsCalendarService,
    private observableService: ObservableService,
    private searchPopupService: SearchPopupService,
    private userDataService: UserDataService,
    private editionsService: EditionsService,
  ) {}

  ngOnInit(): void {
    this.subscriber.add(
      this.observableService.earningsCalendarSymbol
        .pipe(
          take(1),
          switchMap((symbolId) => this.symbolsService.getById(symbolId)),
        )
        .subscribe((symbol) => {
          this.currentSymbol$.next(symbol);
        }),
    );

    this.selectedTab = this.observableService.earningsCalendarTab.getValue();

    this.subscriber.add(
      this.observableService.expectedMoveHasData.subscribe((expectedMoveHasData) => {
        this.expectedMoveHasData$.next(expectedMoveHasData);
      }),
    );

    this.subscriber.add(
      this.tabState$
        .pipe(debounceTime(saveTabStateDebounceTime), distinctUntilChanged())
        .subscribe(async (tabIndex) => {
          this.selectedTab = tabIndex;
          await this.userDataService.set(UserSettings.EarningsCalendarTab, tabIndex);
        }),
    );

    this.subscriber.add(
      this.currentSymbol$
        .pipe(
          tap(() => {
            this.isContentBlurred = true;
          }),
          switchMap((symbol) => {
            if (!symbol) {
              return combineLatest([of(symbol), of(null), of(null)]);
            }

            return combineLatest([
              of(symbol),
              this.earningsCalendarService
                .getEarningsDataForSymbol(symbol.security_id)
                .pipe(catchError(() => of(null))),
              from(this.historicalDataService.get(symbol.security_id)).pipe(catchError(() => of(null))),
            ]);
          }),
        )
        .subscribe(([symbol, earnings, historicalData]) => {
          this.isDataReceived = symbol !== null && earnings !== null && earnings.length > 0;

          if (this.isDataReceived) {
            this.handleDetailsResponse(earnings, historicalData);
          } else {
            this.handleDetailsResponse([], null);
          }

          this.isContentBlurred = false;

          if (symbol) {
            this.isLoading = false;
            // don't need to be awaited
            this.userDataService.set(UserSettings.EarningsCalendarSymbol, symbol.security_id);
          }
        }),
    );

    this.subscriber.add(
      combineLatest([this.earningsBeforeCurrentDate$, this.maxItemsToUse$])
        .pipe(
          map(([earnings, maxItemsToUse]) => {
            if (maxItemsToUse === this.defaultMaxDataToUse) {
              return earnings;
            }

            return earnings.slice(0, maxItemsToUse); // filter data value
          }),
        )
        .subscribe((earnings) => {
          this.updatePriceMoveReactionProbability(earnings);
          this.updateNextDayClosingPriceChanges(earnings);
          this.updateHistoricalEpsPerformance(earnings);
          this.updateChartAndDataGrid(earnings);
        }),
    );

    this.observableService.earningsFilterValue
      .pipe(
        take(1),
        map((maxDataToUse) => (maxDataToUse == null ? this.defaultMaxDataToUse : maxDataToUse)),
      )
      .subscribe((maxDataToUse) => {
        this.maxItemsToUse$.next(maxDataToUse);
        this.rangeValue = maxDataToUse;
      });

    this.filtersChanged$
      .pipe(debounceTime(earningsCalendarFilterDebounceTime), distinctUntilChanged())
      .subscribe(async (value) => {
        this.maxItemsToUse$.next(value);
        await this.userDataService.set(UserSettings.EarningsFilterValue, value);
      });

    this.subscriber.add(
      this.observableService.symbol
        .pipe(
          debounceTime(300),
          switchMap((symbol)=>this.symbolsService.getById(symbol)),
        )
        .subscribe((symbol) => {
          this.currentSymbol$.next(symbol);
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  protected openSymbolSearch(): void {
    this.searchPopupService.openPopup(
      this.currentSymbol$.getValue()?.symbol || '',
      false,
      true,
      false,
      [ExchangeCountriesCodes.US, ExchangeCountriesCodes.CA],
      (selectedSymbol) => {
        this.currentSymbol$.next(selectedSymbol);
      },
      false,
    );
  }

  protected onChangeTab(event: { index: number }): void {
    this.tabState$.next(event.index);
  }

  protected async onChangeSymbol(securityId: number): Promise<void> {
    try {
      const selectedSymbol = await this.symbolsService.getById(securityId);

      if (selectedSymbol) {
        this.currentSymbol$.next(selectedSymbol);
      }
      // eslint-disable-next-line no-empty, @typescript-eslint/no-unused-vars
    } catch (e) {}
  }

  protected onChangeQuartersFilter(changeEvent: ChangeContext): void {
    this.filtersChanged$.next(changeEvent.value);
  }

  protected redirectToExpectedMoveDemoPage(): void {
    this.editionsService.redirectToDemoPage(Features.ExpectedMove);
  }

  private handleDetailsResponse(
    earningItems: IEarningsDetailsApiResponse[],
    historicalData: IHistoricalDataItem[] | null,
  ): void {
    const formattedEarnings = this.formatEarnings(Array.from(earningItems));
    const currentETDate = moment().tz(EasternTimeZoneName);

    this.formattedEarnings = formattedEarnings;
    this.earningsBeforeCurrentDate = Array.from(formattedEarnings).filter((details) =>
      moment(details.reportDate).isBefore(currentETDate, 'day'),
    );

    this.updateNextEarnings(formattedEarnings);
    this.updatePrevEarnings(this.earningsBeforeCurrentDate, historicalData);
    this.earningsBeforeCurrentDate$.next(this.earningsBeforeCurrentDate);
  }

  private updateNextEarnings(earningItems: IEarningsDetails[]): void {
    const earningsAfterCurrentDay = Array.from(earningItems)
      .filter((details) => details.earningsInDays >= 0)
      .sort((a, b) => a.earningsInDays - b.earningsInDays);

    if (earningsAfterCurrentDay[0]) {
      this.nextEarnings = {
        ...earningsAfterCurrentDay[0],
        diffInDaysText: this.getDiffInDaysText(earningsAfterCurrentDay[0].earningsInDays),
      };
    } else {
      this.nextEarnings = null;
    }
  }

  private updatePrevEarnings(earningItems: IEarningsDetails[], historicalData: IHistoricalDataItem[] | null): void {
    const earningsBeforeCurrentDay = earningItems.sort((a, b) => b.earningsInDays - a.earningsInDays);

    if (!earningsBeforeCurrentDay[0]) {
      this.prevEarnings = null;
      return;
    }

    const prevEarnings = earningsBeforeCurrentDay[0];

    // the closing price of the working day before the earnings date
    const lastProcessedDate = historicalData?.length
      ? historicalData[historicalData.length - 1].date.split('T')[0]
      : null;

    const lastTradePrice = moment(lastProcessedDate).tz(EasternTimeZoneName).isAfter(prevEarnings.reportDate, 'day')
      ? historicalData[historicalData.length - 1].close
      : null;

    const nextDayOpenChange = prevEarnings.openNextDay - prevEarnings.closeBeforeDay;

    const recentCloseDiff = lastTradePrice !== null ? round(lastTradePrice - prevEarnings.closeBeforeDay, 2) : null;

    const recentCloseDiffInPercent =
      lastTradePrice !== null ? round((lastTradePrice * 100) / prevEarnings.closeBeforeDay - 100, 2) : null;

    this.prevEarnings = {
      ...prevEarnings,
      lastTradePrice,
      diffInDaysText: this.getDiffInDaysText(prevEarnings.earningsInDays),
      nextDayOpenChange,
      recentCloseDiff,
      recentCloseDiffInPercent,
    };
  }

  private updatePriceMoveReactionProbability(earningItems: IEarningsDetails[]): void {
    const earningsBeforeCurrentDay = Array.from(earningItems)
      .sort((a, b) => b.earningsInDays - a.earningsInDays)
      .splice(0, this.maxItemsNumberForPriceMoveReactionProbability); // take limited number of options

    const validOptions = Array.from(earningsBeforeCurrentDay).filter(
      (details) => details.sevenDayClosePercentChange !== null,
    );

    if (earningsBeforeCurrentDay.length === 0) {
      this.priceMoveReactionProbability = null;
      return;
    }

    if (validOptions.length === 0) {
      this.priceMoveReactionProbability = {
        quartersNumber: earningsBeforeCurrentDay.length,
        up: { count: 0, percent: 0, averagePrice: 0 },
        down: { count: 0, percent: 0, averagePrice: 0 },
        noChange: { count: 0, percent: 0 },
        statistics: { downListAvg: 0, maxDown: 0, minDown: 0, upListAvg: 0, upListMax: 0, upListMin: 0 },
      };

      return;
    }

    const upOptions = Array.from(validOptions).filter((details) => details.sevenDayClosePercentChange > 0);
    const upPercent = round((upOptions.length / validOptions.length) * 100, 2);
    const upPercentAvg = round(average(upOptions.map((item) => item.sevenDayClosePercentChange)), 2) || 0;

    const downOptions = Array.from(validOptions).filter((details) => details.sevenDayClosePercentChange < 0);
    const downPercent = round((downOptions.length / validOptions.length) * 100, 2);
    const downPercentAvg = round(average(downOptions.map((item) => item.sevenDayClosePercentChange)), 2) || 0;

    const noChangeOptions = Array.from(validOptions).filter((details) => details.sevenDayClosePercentChange === 0);
    const noChangePercent = round((noChangeOptions.length / validOptions.length) * 100, 2);

    let upListMax = 0;
    let upListMin = 0;
    let minDown = 0;
    let maxDown = 0;

    if (upOptions.length) {
      const sevenDayOpenPercentChangeOptions = upOptions
        .map((item) => item.sevenDayClosePercentChange)
        .filter((item) => item !== null);

      upListMax = Math.max(...sevenDayOpenPercentChangeOptions);
      upListMin = Math.min(...sevenDayOpenPercentChangeOptions);
    }

    if (downOptions.length) {
      const sevenDayOpenPercentChangeOptions = downOptions
        .map((item) => item.sevenDayClosePercentChange)
        .filter((item) => item !== null);

      minDown = Math.max(...sevenDayOpenPercentChangeOptions);
      maxDown = Math.min(...sevenDayOpenPercentChangeOptions);
    }

    this.priceMoveReactionProbability = {
      quartersNumber: earningsBeforeCurrentDay.length,
      up: { count: upOptions.length, percent: upPercent, averagePrice: upPercentAvg },
      down: { count: downOptions.length, percent: downPercent, averagePrice: downPercentAvg },
      noChange: { count: noChangeOptions.length, percent: noChangePercent },
      statistics: { downListAvg: downPercentAvg, maxDown, minDown, upListAvg: upPercentAvg, upListMax, upListMin },
    };
  }

  private updateNextDayClosingPriceChanges(earningItems: IEarningsDetails[]): void {
    const earningsBeforeCurrentDay = Array.from(earningItems)
      .sort((a, b) => b.earningsInDays - a.earningsInDays)
      .splice(0, this.maxItemsNumberForNextDayClosingPriceChanges);

    const validOptions = Array.from(earningsBeforeCurrentDay).filter(
      (details) => details.nextDayClosePercentChange !== null,
    );

    if (earningsBeforeCurrentDay.length === 0) {
      this.nextDayClosingPriceChanges = null;
      return;
    }

    if (validOptions.length === 0) {
      this.nextDayClosingPriceChanges = {
        quartersNumber: earningsBeforeCurrentDay.length,
        up: { count: 0, percent: 0, averagePrice: 0 },
        down: { count: 0, percent: 0, averagePrice: 0 },
        noChange: { count: 0, percent: 0 },
        statistics: { downListAvg: 0, maxDown: 0, minDown: 0, upListAvg: 0, upListMax: 0, upListMin: 0 },
      };

      return;
    }

    const upOptions = Array.from(validOptions).filter((details) => details.nextDayOpenPercentChange > 0);
    const upPercent = round((upOptions.length / validOptions.length) * 100, 2);
    const upPercentAvg = round(average(upOptions.map((item) => item.nextDayOpenPercentChange)), 2) || 0;

    const downOptions = Array.from(validOptions).filter((details) => details.nextDayOpenPercentChange < 0);

    const downPercent = round((downOptions.length / validOptions.length) * 100, 2);
    const downPercentAvg = round(average(downOptions.map((item) => item.nextDayOpenPercentChange)), 2) || 0;

    const noChangeOptions = Array.from(validOptions).filter((details) => details.nextDayOpenPercentChange === 0);
    const noChangePercent = round((noChangeOptions.length / validOptions.length) * 100, 2);

    let upListMax = 0;
    let upListMin = 0;
    let minDown = 0;
    let maxDown = 0;

    if (upOptions.length) {
      const nextDayOpenPercentChangeOptions = upOptions
        .map((item) => item.nextDayOpenPercentChange)
        .filter((item) => item !== null);

      upListMax = Math.max(...nextDayOpenPercentChangeOptions);
      upListMin = Math.min(...nextDayOpenPercentChangeOptions);
    }

    if (downOptions.length) {
      const nextDayOpenPercentChangeOptions = downOptions
        .map((item) => item.nextDayOpenPercentChange)
        .filter((item) => item !== null);

      minDown = Math.max(...nextDayOpenPercentChangeOptions);
      maxDown = Math.min(...nextDayOpenPercentChangeOptions);
    }

    this.nextDayClosingPriceChanges = {
      quartersNumber: earningsBeforeCurrentDay.length,
      up: { count: upOptions.length, percent: upPercent, averagePrice: upPercentAvg },
      down: { count: downOptions.length, percent: downPercent, averagePrice: downPercentAvg },
      noChange: { count: noChangeOptions.length, percent: noChangePercent },
      statistics: { downListAvg: downPercentAvg, maxDown, minDown, upListAvg: upPercentAvg, upListMax, upListMin },
    };
  }

  private updateHistoricalEpsPerformance(earningItems: IEarningsDetails[]): void {
    const earningsBeforeCurrentDay = Array.from(earningItems)
      .sort((a, b) => b.earningsInDays - a.earningsInDays)
      .splice(0, this.maxItemsNumberForHistoricalEpsPerformance);

    const validOptions = Array.from(earningsBeforeCurrentDay).filter(
      (details) => details.actual !== null && details.estimate !== null,
    );

    if (earningsBeforeCurrentDay.length === 0) {
      this.historicalEpsPerformance = null;
      return;
    }

    if (validOptions.length === 0) {
      this.historicalEpsPerformance = {
        quartersNumber: earningsBeforeCurrentDay.length,
        beat: { count: 0, percent: 0 },
        miss: { count: 0, percent: 0 },
        meet: { count: 0, percent: 0 },
        statistics: { downListAvg: 0, maxDown: 0, minDown: 0, upListAvg: 0, upListMax: 0, upListMin: 0 },
      };

      return;
    }

    // BEAT: if ([Actual EPS] - [Estimated EPS]) > 0
    const beatOptions = Array.from(validOptions).filter((details) => details.actual - details.estimate > 0);
    const beatPercent = round((beatOptions.length / validOptions.length) * 100, 2);

    // MISS: if ([Actual EPS] - [Estimated EPS]) < 0
    const missOptions = Array.from(validOptions).filter((details) => details.actual - details.estimate < 0);
    const missPercent = round((missOptions.length / validOptions.length) * 100, 2);

    // MEET: if ([Actual EPS] - [Estimated EPS]) = 0
    const meetOptions = Array.from(validOptions).filter((details) => details.actual - details.estimate === 0);
    const meetChangePercent = round((meetOptions.length / validOptions.length) * 100, 2);

    // down list: if (item.surpriseEps < 0)
    const downSurpriseEpsOptions = Array.from(validOptions).filter((details) => details.surpriseEps < 0);

    // up list: if (item.surpriseEps > 0)
    const upSurpriseEpsOptions = Array.from(validOptions).filter((details) => details.surpriseEps > 0);

    let upListMax = 0;
    let upListMin = 0;
    let minDown = 0;
    let maxDown = 0;

    if (upSurpriseEpsOptions.length) {
      const surpriseEpsOptions = upSurpriseEpsOptions.map((item) => item.surpriseEps).filter((item) => item !== null);

      upListMax = Math.max(...surpriseEpsOptions);
      upListMin = Math.min(...surpriseEpsOptions);
    }

    if (downSurpriseEpsOptions.length) {
      const surpriseEpsOptions = downSurpriseEpsOptions.map((item) => item.surpriseEps).filter((item) => item !== null);

      minDown = Math.max(...surpriseEpsOptions);
      maxDown = Math.min(...surpriseEpsOptions);
    }

    const upListAvg = round(average(upSurpriseEpsOptions.map((item) => item.surpriseEps)), 2) || 0;
    const downListAvg = round(average(downSurpriseEpsOptions.map((item) => item.surpriseEps)), 2) || 0;

    this.historicalEpsPerformance = {
      quartersNumber: earningsBeforeCurrentDay.length,
      beat: { count: beatOptions.length, percent: beatPercent },
      miss: { count: missOptions.length, percent: missPercent },
      meet: { count: meetOptions.length, percent: meetChangePercent },
      statistics: { downListAvg, maxDown, minDown, upListAvg, upListMax, upListMin },
    };
  }

  private updateChartAndDataGrid(earningItems: IEarningsDetails[]): void {
    const earningsBeforeCurrentDay = Array.from(earningItems)
      .sort((a, b) => b.earningsInDays - a.earningsInDays)
      .splice(0, this.maxItemsNumberForPriceMoveReactionProbability);

    this.dataGridSource = earningsBeforeCurrentDay;
    this.chartSource = earningsBeforeCurrentDay;
  }

  private formatEarnings(rawEarnings: IEarningsDetailsApiResponse[]): IEarningsDetails[] {
    return Array.from(rawEarnings).map((details) => ({
      actual: details.actual,
      beforeAfterMarket: details.before_after_market,
      beforeDay: details.before_day,
      closeBeforeDay: details.close_before_day,
      openNextDay: details.open_next_day,
      nextDayOpenPercentChange: details.next_day_open_percent_change,
      closeNextDay: details.close_next_day,
      currency: details.currency,
      dateConfirmed: details.date_confirmed,
      difference: details.difference,
      earningsInDays: details.earnings_in_days,
      estimate: details.estimate,
      nextDay: details.next_day,
      nextDayClosePercentChange: details.next_day_close_percent_change,
      percent: details.percent,
      period: details.period,
      periodYear: details.period_year,
      reportDate: details.report_date,
      sevenDay: details.seven_day,
      closeSevenDay: details.close_seven_day,
      sevenDayClosePercentChange: details.seven_day_close_percent_change,
      surpriseEps: details.percent !== null ? details.percent * 100 : null,
    }));
  }

  private getDiffInDaysText(daysNumber: number): string {
    if (daysNumber < 0) {
      const absDaysNumber = Math.abs(daysNumber);
      return `${absDaysNumber} ${absDaysNumber === 1 ? 'day' : 'days'} ago`;
    }

    if (daysNumber === 0) {
      return 'Today';
    }

    if (daysNumber === 1) {
      return 'Tomorrow';
    }

    if (daysNumber > 1) {
      return `in ${daysNumber} days`;
    }
  }
}
