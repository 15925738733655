import { Component, Input, OnInit } from '@angular/core';
import { IEarningsDetailsStatistics } from '@t/earning-calendar/earnings-calendar.types';

@Component({
  selector: 'app-statistic-table',
  templateUrl: './statistic-table.component.html',
  styleUrls: ['./statistic-table.component.scss'],
})
export class StatisticTableComponent implements OnInit {
  @Input() surprise: boolean = false;
  @Input() set dataSource(val: IEarningsDetailsStatistics) {
    this.data = new Array(val);
  }

  public data: IEarningsDetailsStatistics[];

  public displayedColumns = ['price', 'min', 'avg', 'max'];

  constructor() { }

  ngOnInit(): void { }
}
