<div #chartContainer class="grouped-chart">
  <div class="header">
    <div class="label-item">
      <div class="chart-marker grey"></div>
      <span class="name">Estimated EPS</span>
    </div>
    <div class="label-item">
      <div class="chart-marker blue"></div>
      <span class="name">Actual EPS</span>
    </div>
  </div>

  <div class="scroll-data chart-scroll-container">
    <div [ngStyle]="{ 'width.px': chartWidth }">
      <apx-chart
        *ngIf="isActive"
        [series]="series"
        [chart]="chartOptions.chart"
        [annotations]="isShowZeroLine ? chartOptions.annotations : undefined"
        [legend]="chartOptions.legend"
        [stroke]="chartOptions.stroke"
        [plotOptions]="chartOptions.plotOptions"
        [dataLabels]="chartOptions.dataLabels"
        [grid]="chartOptions.grid"
        [xaxis]="xAxis"
        [yaxis]="yAxis"
        [title]="chartOptions.title"
      ></apx-chart>
    </div>
  </div>
</div>
