import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { SharedModule } from '@sh/shared.module';
import { LabelModule } from '@c/common/label/label.module';
import { ProgressBarModule } from '@c/common/progress-bar/progress-bar.module';
import { ExpectedMoveModule } from '@c/shared/expected-move/expected-move.module';
import { EditionsSharedModule } from '@containers/editions-demo/editions-shared.module';
import { EarningsCalendarService } from '@s/calendars/earnings-calendar.service';
import { WeeksSelectorModule } from '@c/shared/weeks-selector/weeks-selector.module';
import { EarningsAnalysisContentComponent } from '@c/earnings-analysis-content/earnings-analysis-content.component';
import { EpsChartComponent } from '@c/earnings-analysis-content/eps-chart/eps-chart.component';
import { EpsTableComponent } from '@c/earnings-analysis-content/eps-table/eps-table.component';
import { StatisticTableComponent } from '@c/earnings-analysis-content/statistic-table/statistic-table.component';

@NgModule({
  declarations: [
    EpsChartComponent,
    EpsTableComponent,
    StatisticTableComponent,
    EarningsAnalysisContentComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    SharedModule,
    MatTabsModule,
    NgApexchartsModule,
    LabelModule,
    ProgressBarModule,
    NgxSliderModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    ExpectedMoveModule,
    EditionsSharedModule,
    MatButtonModule,
    WeeksSelectorModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
  ],
  exports: [EarningsAnalysisContentComponent],
  providers: [EarningsCalendarService],
})
export class EarningsCalendarContentModule { }
