<div
  [ngClass]="{ 'chart-tab': isChartTab() }"
  class="earnings container scroll-data"
>
  <div class="earnings-header">
    <h3 class="modal-header">Earnings Analysis</h3>
  </div>

  <div class="pxo-tab-group pxo-tab-group-default">
    <div class="search-section">
      @if (!isChartTab()) {
        <div class="select-company-wrapper">
          <div class="search-box-with-add">
            <div
              [matTooltip]="'Symbol Search'"
              [matTooltipHideDelay]="0"
              [matTooltipPosition]="'below'"
              (click)="openSymbolSearch()"
              class="fill-btn search-bar-btn"
            >
              {{ (currentSymbol$ | async)?.symbol }}
            </div>
          </div>

          <div
            *ngIf="currentSymbol$ | async"
            class="company-name"
          >
            <mat-icon
              [svgIcon]="(currentSymbol$ | async)?.country_code + '_exchange_icon'"
              class="country-flag"
            ></mat-icon>

            <div class="name-time">
              <p class="s2 boldText ellipsis-text">
                {{ (currentSymbol$ | async)?.description }}
              </p>
            </div>
          </div>
        </div>
      }
      <div class="range-slider-wrapper">
        <span>Show recent quarters</span>
        <div class="range-slider-item searching-slider">
          <ngx-slider
            [(value)]="rangeValue"
            [options]="options"
            (userChange)="onChangeQuartersFilter($event)"
          ></ngx-slider>
        </div>
      </div>
    </div>

    <div
      *ngIf="isDataReceived && !isLoading"
      class="earnings-report-body"
    >
      <div
        *ngIf="isContentBlurred"
        class="blur-overlay"
      ></div>

      <div class="earnings-reports">
        <!-- next earnings -->
        <div
          [class.expected]="expectedMoveHasData$ | async"
          *ngIf="nextEarnings"
          class="item-report item-report-next"
        >
          <div class="report-date-info">
            <div class="date-wrap">
              <span class="date">{{ nextEarnings.reportDate | date: 'EEE d, MMM' }}</span>
              <app-label
                [text]="nextEarnings.diffInDaysText"
                appendClass="primary"
              ></app-label>
            </div>
            <span class="explanation">Next earnings date</span>

            <!-- TODO: check why there is code-duplication -->
            <div
              *ngIf="nextEarnings.beforeAfterMarket"
              class="report-info"
            >
              <div class="relative-to-market-time">
                <mat-icon
                  class="time-icon"
                  svgIcon="time-icon"
                ></mat-icon>
                <p class="timing">
                  {{ relativelyToMarketTimeLabels[nextEarnings.beforeAfterMarket] }}
                </p>
              </div>

              <div class="report-body">
                <app-label
                  *ngIf="nextEarnings.dateConfirmed === 1"
                  text="Confirmed"
                  iconName="success-icon"
                  appendClass="success"
                ></app-label>
                <app-label
                  *ngIf="nextEarnings.dateConfirmed === 0"
                  text="Not confirmed"
                  appendClass="danger"
                ></app-label>
              </div>
            </div>
          </div>

          <div
            *ngIf="nextEarnings.beforeAfterMarket"
            class="report-info"
          >
            <div class="relative-to-market-time">
              <mat-icon
                class="time-icon"
                svgIcon="time-icon"
              ></mat-icon>
              <p class="timing">
                {{ relativelyToMarketTimeLabels[nextEarnings.beforeAfterMarket] }}
              </p>
            </div>
            <div class="report-body">
              <app-label
                *ngIf="nextEarnings.dateConfirmed === 1"
                text="Confirmed"
                iconName="success-icon"
                appendClass="success"
              ></app-label>
              <app-label
                *ngIf="nextEarnings.dateConfirmed === 0"
                text="Not confirmed"
                appendClass="danger"
              ></app-label>
            </div>
          </div>

          <app-expected-move
            [ngClass]="{ hide: !(expectedMoveHasData$ | async) }"
            [modelView]="'short'"
            [securityId]="(currentSymbol$ | async)?.security_id"
            [checkNextEarning]="true"
            [liveDataIntervalSeconds]="30"
            [hideRockyIcon]="true"
            *appHideForUnavailableFeature="features.ExpectedMove; else expectedMovePlaceholder"
          ></app-expected-move>

          <ng-template #expectedMovePlaceholder>
            <div class="expected-move-placeholder">
              <button
                (click)="redirectToExpectedMoveDemoPage()"
                mat-button
                class="expected-move-demo-button locked-for-current-edition"
              >
                Expected Move
              </button>
            </div>
          </ng-template>
        </div>

        <!-- No data report card -->
        <div
          *ngIf="!nextEarnings"
          class="item-report item-report-next no-data-report"
        >
          <div class="report-date-info">
            <div class="date-wrap">
              <span class="date">Next earnings date</span>
            </div>
            <span class="explanation">
              <mat-icon
                class="small-icon"
                svgIcon="search-icon"
              ></mat-icon>
              No data found
            </span>
          </div>
        </div>

        <!-- previous earnings -->
        <div
          *ngIf="prevEarnings"
          class="item-report item-report-prev"
        >
          <div class="report-date-info">
            <div class="date-wrap">
              <span class="date">{{ prevEarnings.reportDate | date: 'EEE d, MMM' }}</span>
              <app-label
                [text]="prevEarnings.diffInDaysText"
                appendClass="primary"
              ></app-label>
            </div>
            <p class="explanation">Previous earnings date</p>

            <div class="relative-to-market-time">
              <mat-icon
                class="time-icon"
                svgIcon="time-icon"
              ></mat-icon>
              <span class="timing">
                {{ relativelyToMarketTimeLabels[prevEarnings.beforeAfterMarket] }}
              </span>
            </div>
          </div>

          <div class="report-info">
            <div class="report-header-container">
              <h3 class="report-header">Stock price change since last earnings released</h3>
            </div>

            <div class="report-body">
              <div
                *ngIf="prevEarnings.closeBeforeDay !== null"
                class="report-info-price"
              >
                <div class="values">
                  <p class="price">{{ prevEarnings.closeBeforeDay | number: '1.2-2' }}</p>
                </div>

                <p class="explanation">Closing price before earnings</p>
              </div>

              <div
                *ngIf="prevEarnings.openNextDay !== null && prevEarnings.closeBeforeDay !== null"
                class="report-info-price"
              >
                <div class="values">
                  <p class="price">
                    {{ prevEarnings.openNextDay | number: '1.2-2' }}
                  </p>
                  <p
                    [ngClass]="{
                      success: prevEarnings.nextDayOpenChange > 0,
                      fail: prevEarnings.nextDayOpenChange < 0,
                    }"
                    class="price-change"
                  >
                    {{ prevEarnings.nextDayOpenChange > 0 ? '+' : ''
                    }}{{ prevEarnings.nextDayOpenChange | number: '1.0-6' }}
                  </p>
                  <p
                    [ngClass]="{
                      success: prevEarnings.nextDayOpenPercentChange > 0,
                      fail: prevEarnings.nextDayOpenPercentChange < 0,
                    }"
                    class="price-change-percent"
                  >
                    {{ prevEarnings.nextDayOpenPercentChange > 0 ? '+' : ''
                    }}{{ prevEarnings.nextDayOpenPercentChange | number: '1.0-2' }}
                    %
                  </p>
                </div>
                <p class="explanation">Opening price after earnings</p>
              </div>

              <div
                *ngIf="prevEarnings.lastTradePrice !== null && prevEarnings.closeBeforeDay !== null"
                class="report-info-price"
              >
                <div class="values">
                  <p class="price">
                    {{ prevEarnings.lastTradePrice | number: '1.2-2' }}
                  </p>
                  <p
                    [ngClass]="{ success: prevEarnings.recentCloseDiff > 0, fail: prevEarnings.recentCloseDiff < 0 }"
                    class="price-change"
                  >
                    {{ prevEarnings.recentCloseDiff > 0 ? '+' : ''
                    }}{{ prevEarnings.recentCloseDiff | number: '1.0-6' }}
                  </p>
                  <p
                    [ngClass]="{
                      success: prevEarnings.recentCloseDiffInPercent > 0,
                      fail: prevEarnings.recentCloseDiffInPercent < 0,
                    }"
                    class="price-change-percent"
                  >
                    {{ prevEarnings.recentCloseDiffInPercent > 0 ? '+' : ''
                    }}{{ prevEarnings.recentCloseDiffInPercent | number: '1.0-2' }}
                    %
                  </p>
                </div>
                <p class="explanation">Recent closing price</p>
              </div>
            </div>
          </div>
        </div>

        <!-- No data large report card -->
        <div
          *ngIf="!prevEarnings"
          class="item-report item-report-prev no-data-report"
        >
          <div class="report-date-info">
            <div class="date-wrap">
              <span class="date">Previous earnings date</span>
            </div>
            <span class="explanation">
              <mat-icon
                class="small-icon"
                svgIcon="search-icon"
              ></mat-icon>
              No data found
            </span>
          </div>
        </div>
      </div>

      <div class="statistics">
        <!-- next day closing price changes -->
        <div
          *ngIf="nextDayClosingPriceChanges"
          class="statistics-item next"
        >
          <div class="header-content">
            <h3>
              Overnight price move reaction probability
              <mat-icon
                [svgIcon]="'hint_icon'"
                [matTooltip]="overnightHint"
                [matTooltipHideDelay]="0"
                class="small-icon hint-icon"
              ></mat-icon>
            </h3>
            <span class="sub-header">
              based on recent {{ nextDayClosingPriceChanges.quartersNumber }}
              {{ nextDayClosingPriceChanges.quartersNumber === 1 ? 'quarter' : 'quarters' }}
            </span>
          </div>

          <div class="bars">
            <div class="bar-item">
              <div class="bar-info">
                <span class="bar-name">Up</span>
                <span class="value">
                  <span
                    *ngIf="nextDayClosingPriceChanges.up.count !== 0; else noValue"
                    class="amount"
                  >
                    {{ nextDayClosingPriceChanges.up.count }} ({{
                      nextDayClosingPriceChanges.up.percent | number: '1.2-2'
                    }}%)
                  </span>
                </span>
              </div>
              <app-progress-bar
                [barColor]="'success'"
                [value]="nextDayClosingPriceChanges.up.percent"
              ></app-progress-bar>
            </div>
            <div class="bar-item">
              <div class="bar-info">
                <span class="bar-name">Down</span>
                <span class="value">
                  <span
                    *ngIf="nextDayClosingPriceChanges.down.count !== 0; else noValue"
                    class="amount"
                  >
                    {{ nextDayClosingPriceChanges.down.count }} ({{
                      nextDayClosingPriceChanges.down.percent | number: '1.2-2'
                    }}%)
                  </span>
                </span>
              </div>
              <app-progress-bar
                [barColor]="'danger'"
                [value]="nextDayClosingPriceChanges.down.percent"
              ></app-progress-bar>
            </div>
            <div class="bar-item">
              <div class="bar-info">
                <span class="bar-name">No change</span>
                <span class="value">
                  <span
                    *ngIf="nextDayClosingPriceChanges.noChange.count !== 0; else noValue"
                    class="amount"
                  >
                    {{ nextDayClosingPriceChanges.noChange.count }} ({{
                      nextDayClosingPriceChanges.noChange.percent | number: '1.2-2'
                    }}%)
                  </span>
                </span>
              </div>
              <app-progress-bar [value]="nextDayClosingPriceChanges.noChange.percent"></app-progress-bar>
            </div>
          </div>
          <app-statistic-table [dataSource]="nextDayClosingPriceChanges.statistics"></app-statistic-table>
        </div>

        <!-- price move reaction probability -->
        <div
          *ngIf="priceMoveReactionProbability"
          class="statistics-item reaction"
        >
          <div class="header-content">
            <h3>Price move reaction probability on 7<sup>th</sup> day</h3>
            <span class="sub-header">
              based on recent {{ priceMoveReactionProbability.quartersNumber }}
              {{ priceMoveReactionProbability.quartersNumber === 1 ? 'quarter' : 'quarters' }}
            </span>
          </div>
          <div class="bars">
            <div class="bar-item">
              <div class="bar-info">
                <span class="bar-name">Up</span>
                <span class="value">
                  <span
                    *ngIf="priceMoveReactionProbability.up.count !== 0; else noValue"
                    class="amount"
                  >
                    {{ priceMoveReactionProbability.up.count }} ({{
                      priceMoveReactionProbability.up.percent | number: '1.2-2'
                    }}%)
                  </span>
                </span>
              </div>
              <app-progress-bar
                [barColor]="'success'"
                [value]="priceMoveReactionProbability.up.percent"
              ></app-progress-bar>
            </div>
            <div class="bar-item">
              <div class="bar-info">
                <span class="bar-name">Down</span>
                <span class="value">
                  <span
                    *ngIf="priceMoveReactionProbability.down.count !== 0; else noValue"
                    class="amount"
                  >
                    {{ priceMoveReactionProbability.down.count }} ({{
                      priceMoveReactionProbability.down.percent | number: '1.2-2'
                    }}%)
                  </span>
                </span>
              </div>
              <app-progress-bar
                [barColor]="'danger'"
                [value]="priceMoveReactionProbability.down.percent"
              ></app-progress-bar>
            </div>
            <div class="bar-item">
              <div class="bar-info">
                <span class="bar-name">No change</span>
                <span class="value">
                  <span
                    *ngIf="priceMoveReactionProbability.noChange.percent !== 0; else noValue"
                    class="value"
                  >
                    {{ priceMoveReactionProbability.noChange.count }} ({{
                      priceMoveReactionProbability.noChange.percent | number: '1.2-2'
                    }}%)
                  </span>
                </span>
              </div>
              <app-progress-bar [value]="priceMoveReactionProbability.noChange.percent"></app-progress-bar>
            </div>
          </div>
          <app-statistic-table [dataSource]="priceMoveReactionProbability.statistics"></app-statistic-table>
        </div>

        <!-- historical EPS performance -->
        <div
          *ngIf="historicalEpsPerformance"
          class="statistics-item performance"
        >
          <div class="header-content">
            <h3>Historical EPS performance</h3>
            <span class="sub-header">
              based on recent {{ historicalEpsPerformance.quartersNumber }}
              {{ historicalEpsPerformance.quartersNumber === 1 ? 'quarter' : 'quarters' }}
            </span>
          </div>
          <div class="bars">
            <div class="bar-item">
              <div class="bar-info">
                <span class="bar-name">Beat</span>
                <span
                  *ngIf="historicalEpsPerformance.beat.count !== 0; else noValue"
                  class="value"
                >
                  {{ historicalEpsPerformance.beat.count }} ({{
                    historicalEpsPerformance.beat.percent | number: '1.2-2'
                  }}%)
                </span>
              </div>
              <app-progress-bar
                [barColor]="'success'"
                [value]="historicalEpsPerformance.beat.percent"
              ></app-progress-bar>
            </div>
            <div class="bar-item">
              <div class="bar-info">
                <span class="bar-name">Miss</span>
                <span
                  *ngIf="historicalEpsPerformance.miss.count !== 0; else noValue"
                  class="value"
                >
                  {{ historicalEpsPerformance.miss.count }} ({{
                    historicalEpsPerformance.miss.percent | number: '1.2-2'
                  }}%)
                </span>
              </div>
              <app-progress-bar
                [barColor]="'danger'"
                [value]="historicalEpsPerformance.miss.percent"
              ></app-progress-bar>
            </div>
            <div class="bar-item">
              <div class="bar-info">
                <span class="bar-name">Meet</span>
                <span
                  *ngIf="historicalEpsPerformance.meet.count !== 0; else noValue"
                  class="value"
                >
                  {{ historicalEpsPerformance.meet.count }} ({{
                    historicalEpsPerformance.meet.percent | number: '1.2-2'
                  }}%)
                </span>
              </div>
              <app-progress-bar [value]="historicalEpsPerformance.meet.percent"></app-progress-bar>
            </div>
          </div>
          <app-statistic-table
            [dataSource]="historicalEpsPerformance.statistics"
            [surprise]="true"
          ></app-statistic-table>
        </div>
      </div>

      <div
        *ngIf="formattedEarnings.length > 0"
        class="charts"
      >
        <h2 class="charts-header">Historical EPS and price change</h2>
        <div class="pxo-tab-group pxo-tab-group-default">
          <mat-tab-group
            [selectedIndex]="selectedTab"
            (selectedTabChange)="onChangeTab($event)"
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            animationDuration="200"
            color="primary"
          >
            <mat-tab label="EPS Chart">
              <app-eps-chart
                [isActive]="selectedTab === 0"
                [formattedEarnings]="chartSource"
              ></app-eps-chart>
            </mat-tab>
            <mat-tab label="Data Grid">
              <app-eps-table [formattedEarnings]="dataGridSource"></app-eps-table>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>

    <div
      *ngIf="isLoading"
      class="spinner-container"
    >
      <mat-spinner
        class="blue-spinner"
        diameter="50"
      ></mat-spinner>
    </div>

    <div
      *ngIf="!isDataReceived && !isLoading"
      class="nodata-wrapper"
    >
      <div class="content">
        <div class="no-data-img"></div>
        <h4 class="modal-header">No data found</h4>
        <span class="nodata-sub-header">Please select another symbol.</span>
      </div>
    </div>
  </div>
</div>

<ng-template #noValue>
  <span class="value">0</span>
</ng-template>
